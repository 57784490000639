import React, { Component } from 'react';
import Modal from '@sections/orders/actions/modal';
import { toast } from 'react-toastify';
import { formatNumber, customTitle } from '../../util';
import UzdevumiModal from '../orders/uzdevumiModal';
import VedumiModal from '../orders/vedumiModal';
import { checkPermission } from '../../permissions';

import MyCustomTable from '../../latraps_table';

import CustomTabulator from './customTabulator';
import { connect } from 'react-redux';
import { handleInclusion } from './slice';
import './table.css';

const actionsButtonFormatter = () => (
	<i
		className='fas fa-ellipsis-v'
		style={{ fontSize: '2em', textAlign: 'center', color: 'green' }}></i>
);

const deliverTillFormatter = cell => {
	const { deliverTill: deliveryDate, deliverTillMandantory: mandantory } = cell;
	if (deliveryDate == null || deliveryDate == '00.00.0000') {
		return '';
	}
	if (mandantory) {
		return <span style={{ fontWeight: 'bold', color: 'red' }}>{deliveryDate}</span>;
	}
	return deliveryDate;
};

const amountFormatter = (cell, section) => {
	const data = cell; //cell.getRow().getData();
	const { [section]: section1, measurementUnit } = data; //cell._cell.row.data;

	const amount = section1 == null ? 0 : section1;

	//console.log(section1, measurementUnit);

	return `${formatNumber(amount, 3)} ${measurementUnit}`;
};

const includingBackgroundColor = (row, deliveriesForInclusion) => {
	const cell = row.original;

	const { remaining, orderIdReal } = cell;

	let bgColor;

	if (remaining < 0.0009) {
		bgColor = '#FF7F50';
	} else if (deliveriesForInclusion.includes(orderIdReal)) {
		bgColor = '#ADFF2F';
	} else {
		bgColor = '#CD5C5C';
	}

	return bgColor;
}
const includingFormatter = (cell, deliveriesForInclusion) => {
	const { remaining, orderIdReal } = cell;

	//console.log(cell, orderId, deliveriesForInclusion)

	let text, bgColor, icon;

	if (remaining < 0.0009) {
		text = 'Nevar iekļaut';
		bgColor = '#FF7F50';
		icon = 'fas fa-exclamation-triangle';
	} else if (deliveriesForInclusion.includes(orderIdReal)) {
		text = 'Iekļauts';
		bgColor = '#ADFF2F';
		icon = 'fas fa-check-circle';
	} else {
		text = 'Nav iekļauts';
		bgColor = '#CD5C5C';
		icon = 'fas fa-times-circle';
	}
	//cell.getElement().style.backgroundColor = bgColor;

	// style="color:${bgColor};"
	// style='background-color:${bgColor}'
	return (
		<div style={{ width: '100% ', fontSize: '1.3em', backgroundColor: bgColor }}>
			<i className={icon} title={text} style={{ fontSize: '1.5em' }}></i>
		</div>
	);
	//return `<span class="${icon}" title="${text}"></span>`;
};

const notesFormatter = cell => {
	const value = cell; //cell.getRow().getData();
	const notes = typeof value.notes !== 'undefined' ? value.notes : '';
	return (
		<span style={{ color: '#ff0000' }} className='preWrap'>
			{notes} [pasūtījumu ievadīja: {value.worker}]
		</span>
	);
};

class Table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searching: false,
			currentlyEditingUser: null,
			openedModal: ''
		};
	}

	handleOpenActions(cell) {
		this.handleOpenModal(cell, 'actions');
	}

	handleCellClick(cellObject) {
		if (!checkPermission('add')) {
			return false;
		}

		const { remaining } = cellObject;

		if (remaining > 0.0009) {
			this.props.onInclusion(cellObject.orderIdReal);
		} else {
			toast.info('Šo pasūtījumu nevar iekļaut');
		}
	}

	openActionsModal = row => {
		this.handleOpenModal(row, 'actions')
	}

	columns = [
		{
			Header: () => (
				<i
					title='Pasūtījuma numurs'
					className='fas fa-list-ol'
					style={{ color: '#333334' }}></i>
			),
			width: 100,
			accessor: 'orderId',
			Cell: ({ row }) => (
				<div>
					{row.original.orderId}
					{row.original.planningOrderType === 1 &&
						<strong title='Izvešanas plānošanas pas.nr.'>  #{row.original.planningOrderId}</strong>
					}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Vai iekļaut šo pasūtījumu saliktajā kravā?'
					className='fas fa-plus'
					style={{ color: '#333334' }}></i>
			),
			width: 70,
			bgColor: row => includingBackgroundColor(row, this.props.deliveriesForInclusion),
			accessor: 'includedDelivery',
			Cell: ({ row }) => (
				<div className="centerAligned" onClick={() => this.handleCellClick(row.original)}>
					{includingFormatter(row.original, this.props.deliveriesForInclusion)}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Atvērt darbību sarakstu'
					className='fas fa-edit'
					style={{ color: '#333334' }}></i>
			),
			width: 55,
			id: 'darb_sar',
			clickHandler: this.openActionsModal,
			Cell: ({ row }) => (
				<div className="centerAligned">
				<i
					className='fas fa-ellipsis-v'
					style={{ fontSize: '1.6em', textAlign: 'center', color: 'green' }}></i>
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Uzdevumu skaits'
					className='fas fa-tasks'
					style={{ color: '#333334' }}></i>
			),
			width: 55,
			columnClass: 'orderedAmount',
			accessor: 'numberOfTasks',
			Cell: ({ row }) => (
				<div className="centerAligned" onClick={() => this.handleOpenModal(row.original, 'tasks')}>
				{row.original.numberOfTasks}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Vedumu skaits'
					className='fas fa-tasks'
					style={{ color: '#333334' }}></i>
			),
			width: 55,
			columnClass: 'deliveredAmount',
			accessor: 'numberOfVedumi',
			Cell: ({ row }) => (
				<div className="centerAligned" onClick={() => this.handleOpenModal(row.original, 'vedumi')}>
				{row.original.numberOfVedumi}
				</div>
			)
		},
		{
			Header: () => (
				<i
					title='Materiāls'
					className='fas fa-seedling'
					style={{ color: '#333334' }}></i>
			),
			width: 200,
			accessor: 'material'
		},
		{
			Header: () => (
				<i
					title='Piezīmes'
					className='fas fa-clipboard'
					style={{ color: '#333334' }}></i>
			),
			width: 200,
			accessor: 'notes',
			Cell: ({ row }) => (
				<div className="shrinkLines">
				{notesFormatter(row.original)}
				</div>
			)
		},
		{
			width: 120,
			Header: () => (
				<i
					title='Pasūtītais daudzums'
					className='fas fa-shipping-fast'
					style={{
						color: '#e8e8e8',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'orderedAmount',
			accessor: 'orderedAmount',
			Cell: ({ row }) => (
				<div style={{display: 'table-cell'}}>
					{amountFormatter(row.original, 'orderedAmount')}
				</div>
			)
		},
		{
			width: 120,
			Header: () => (
				<i
					title='Pasūtījuma atlikums'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCCCC',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'remainingAmount',
			accessor: 'remainder',
			Cell: ({ row }) => (
				<div>
					{amountFormatter(row.original, 'remainder')}
				</div>
			)
		},
		{
			width: 120,
			Header: () => (
				<i
					title='Daudzums, kas pašlaik ir uzdevumos'
					className='fas fa-shipping-fast'
					style={{
						color: '#FFCC66',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'amountInTasks',
			accessor: 'inTasks',
			Cell: ({ row }) => (
				<div>
					{amountFormatter(row.original, 'inTasks')}
				</div>
			)
		},
		{
			width: 120,
			Header: () => (
				<i
					title='Aizvestais daudzums'
					className='fas fa-shipping-fast'
					style={{
						color: '#CCFF99',
						textShadow: '-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000'
					}}></i>
			),
			columnClass: 'deliveredAmount',
			accessor: 'deliveredByFact',
			Cell: ({ row }) => (
				<div>
					{amountFormatter(row.original, 'deliveredByFact')}
				</div>
			)
		},
		{
			Header: () => (
				<i title='Piegādāt līdz' className='fas fa-clock' style={{ color: '#333334' }}></i>
			),
			accessor: 'deliverTill',
			Cell: ({ row }) => <div>{deliverTillFormatter(row.original)}</div>
		}
	];

	handleCloseModal = (update = false) => {
		this.setState({ openedModal: '' });
	};

	handleOpenModal = (cell, modalToOpen) => {
		const { orderId, orderNr, orderIdReal, remaining } = cell;

		this.setState({
			showModal: true,
			openedModal: modalToOpen,
			orderId,
			orderNr: orderId,
			remainder: remaining,
			orderIdReal
		});
	};

	handleUpdate = () => {
		this.props.handleUpdate();
	};

	render() {
		const { data } = this.props;
		const { orderIdReal, orderNr, openedModal, remainder } = this.state;

		const { data: da1, ...otherData } = this.props;

		return (
			<>
				<div className='tableWrapper'>
					<MyCustomTable
						columns={this.columns}
						orders={data}
					/>
				</div>
				{openedModal === 'actions' && (
					<Modal
						showModal={openedModal === 'actions'}
						orderId={orderIdReal}
						remainder={remainder}
						closeModalWithoutReload={this.handleCloseModal}
						onSuccSave={this.handleUpdate}
					/>
				)}
				{openedModal === 'tasks' && (
					<UzdevumiModal
						showModal={openedModal === 'tasks'}
						orderId={orderIdReal}
						orderNr={orderNr}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
				{openedModal === 'vedumi' && (
					<VedumiModal
						showModal={openedModal === 'vedumi'}
						orderId={orderIdReal}
						orderNr={orderNr}
						handleCloseModal={this.handleCloseModal}
					/>
				)}
			</>
		);
	}
}

const mapDispatchToPropsRes = dispatch => {
	return {
		onInclusion: (orderID) => {
			dispatch(handleInclusion(orderID));
		}
	};
};

const mapStateToPropsRes = state => {
	return {
		deliveriesForInclusion: state.planning.orders,
		allPlanningData: state.planning.planning_data,
		loading: state.planning.loading,
		planningSections: state.planning.sections
	};
};

export default connect(mapStateToPropsRes, mapDispatchToPropsRes)(Table);
